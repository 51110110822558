import URL, { Address } from 'url-parse';

import { stringify as qsStringify } from './query';
import { parse as parseUrl } from './url';

export interface IRouteInfo {
  url: URL;
  byggNavnId: string | null;
  controller: string | null;
  action: string | null;
  id: string | null;
  api: boolean;
  query: any | null;
}

export const parse = (url: Address): IRouteInfo => {
  const parsed = parseUrl(url);
  const { pathname, query } = parsed;
  const fragments = pathname.split('/');
  // Remove first empty segment
  fragments.shift();

  let api = false;
  if (fragments[0] === 'api') {
    api = true;
    fragments.shift();
  }

  let byggNavnId: string | null = null;
  if (fragments[0]) {
    byggNavnId = fragments[0];
    fragments.shift();
  }

  let controller: string | null = null;
  if (fragments[0]) {
    controller = fragments[0];
    fragments.shift();
  }

  let action: string | null = null;
  if (fragments[0]) {
    action = fragments[0];
    fragments.shift();
  }

  let id: string | null = null;
  if (fragments[0]) {
    id = fragments[0];
    fragments.shift();
  }

  return {
    action,
    api,
    byggNavnId,
    controller,
    id,
    query,
    url: parsed,
  };
};

export interface ICreateOptions {
  byggNavnId?: string;
  controller?: string;
  action?: string;
  id?: string;
  api?: boolean;
  query?: any;
  keepQuery?: boolean;
  allowDots?: boolean;
}

export const create = ({
  byggNavnId,
  controller = null,
  action = null,
  id = null,
  api = false,
  query = null,
  keepQuery = false,
  allowDots = false,
}: ICreateOptions) => {
  const parsed = parse(location);
  const { url } = parsed;

  if (byggNavnId) {
    parsed.byggNavnId = byggNavnId;
  } else if (!parsed.byggNavnId) {
    throw new Error(`Error in generating URL. No byggNavnId given, and it was not found in the URL.`);
  }

  if (controller) {
    parsed.controller = controller;
  } else if (!parsed.controller) {
    parsed.controller = 'Hovedside';
  }

  if (action) {
    parsed.action = action;
  } else if (!parsed.action) {
    parsed.action = 'Index';
  }

  if (id) {
    parsed.id = id;
  } else if (byggNavnId || controller || action) {
    // Never keep id when changing route.
    parsed.id = null;
  }

  if (keepQuery) {
    if (parsed.query && !query) {
      query = parsed.query;
    } else if ((!parsed.query && query) || (!parsed.query && !query)) {
      // do nothing
    } else {
      query = Object.assign(parsed.query, query);
    }
  }

  const newSegments = [parsed.byggNavnId];
  if (api) {
    newSegments.unshift('api');
  }

  if (parsed.controller !== 'Hovedside' || parsed.action !== 'Index' || parsed.id !== null) {
    newSegments.push(parsed.controller!);
  }

  if (parsed.action !== 'Index' || parsed.id !== null) {
    newSegments.push(parsed.action!);
  }

  if (parsed.id !== null) {
    newSegments.push(parsed.id!);
  }

  const newPath = newSegments.map(encodeURIComponent).join('/');
  url.set('pathname', newPath);

  if (query) {
    Object.keys(query).forEach(k => {
      if (query[k] === null) {
        delete query[k];
      }
    });

    if (allowDots) {
      url.set('query', '?' + qsStringify(query, { allowDots: true }));
    } else {
      url.set('query', '?' + qsStringify(query));
    }

  } else {
    url.set('query', '');
  }

  url.set("hash", "");

  return url.toString();
};
